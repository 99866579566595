import { Inject, Injectable, signal } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loading = signal<boolean>(false);

  constructor(@Inject(DOCUMENT) private _document: Document) {
  }

  setLoading(val: boolean): void {
    this.loading.update(() => {
      if (val) {
        this._document.body.style.overflow = 'hidden';
      } else {
        this._document.body.style.overflow = 'auto';
      }
      return val;
    })
  }

}
